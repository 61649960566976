<template>
  <v-card>
    <v-toolbar :color="helpColor()" lighten>
      <v-toolbar-title v-if="getLang() === 'no'">Hjelp i bruk av A til Å for elever.</v-toolbar-title>
      <v-toolbar-title v-if="getLang() === 'en'">Help in use of AtoZ for students.</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="getLang() === 'no'" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Miljø</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Her ligger klassereglene dere er blitt enige om i klassen for hvordan dere vil ha det hos dere for at
              læringsmiljøet skal være best mulig. Tenk over hvordan du selv syns du har bidratt til at det skal være
              bra og marker på linjen. Når du trykker på de forskjellige ikonene, kommer reglene dere har bestemt opp i
              tilfelle du ikke husker hva dere har blitt enige om.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/miljo-1.png')" height="200" />
            <v-card-text>
              Her skal du også skrive inn noe du har gjort i skolesammenheng som du er stolt av.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/miljo-2.png')" height="200" />
            <v-card-text>
              Skolen ønsker å vite hvordan du har det på skolen. Svar på spørsmålene rektor har lagt inn for hvordan du
              opplever skolemiljøet.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/miljo-3.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Mine fag</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Her ligger en oversikt over de fagene læreren din ønsker at du skal se, og forholde deg til nå.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/fag-1.png')" height="200" />
            <v-card-text>
              Trykk på pilen for det faget du vil se og og vurder hvordan du har jobbet med faget. Ved å trykke på
              ikonene får du opp hva de representerer.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/fag-2.png')" height="200" />
            <v-card-text>
              Svar på spørsmålene om hva du har lært og vurder hvordan du ligger an i læringsmålene dere jobber med i
              faget (på vei/ kan / behersker). Her kan du også skrive en melding til læreren om det er noe du har lyst
              til kommentere (om noe var vanskelig, gøy, osv.)
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/fag-3.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Hjemmearbeid</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Marker på linjen hvor lang tid du har brukt på regning og lesing.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/hjemme-1.png')" height="200" />
            <v-card-text>
              Ved å trykke på statistikksymbolet, kan du se en oversikt over hvor mye tid du har brukt på hjemmearbeid i
              perioden.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/hjemme-2.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Profil</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Her kan du oppdatere informasjonen om deg. Du kan også velge om du vil se alle kompetansemål og læringsmål
              i faget eller bare de som læreren din vil at du skal se.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/elev/profil-1.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- ** LANGUAGE ** ENGLISH **  -->
    <v-container v-if="getLang() === 'en'" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="10">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Help in using our systems</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Please contact us at learner@hypatia.no for instructions on how to use our services.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: 'HelpLearner',
  data() {
    return {}
  },
  methods: {
    getLang() {
      return this.$store.getters.getLanguage
    },
    helpColor() {
      return 'blue darken-1'
    },
    subjectColor() {
      return 'blue lighten-3'
    }
  }
}
</script>
<style lang="scss" scoped></style>
