<template>
  <v-container fluid>
    <v-row justify="center" align="start">
      <v-col cols="12" sm="10" md="8" lg="4" xl="2">
        <v-img contain position="center center" :src="require('@/assets/ataa-hypatia.svg')" />
      </v-col>
    </v-row>
    <v-row justify="center" align="start">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="start">
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                  <h1 class="heading">Hvordan legge A til Å som en app på hjemskjerm på nettbrett og telefon.</h1>
                  <h2 class="subheading">
                    Instrukser for Apple og IOS.
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <v-card height="0" style="text-align: center;">
                <iframe
                  src="https://player.vimeo.com/video/415449621"
                  width="640"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                >
                </iframe>
                <p class="txt">Android telefoner foreslår automatisk å legge A til Å som en app på hjemskjerm.</p>
              </v-card>
            </v-col>
          </v-row>
          <!--
          <v-row justify="center" align="start">
            <v-col cols="12" md="8" order="1" order-md="2">
              <v-card flat>
                <v-card-text>
                  <div class="txt">Fra din smarttelefon eller nettbrett gå til nettsiden https://elev.ataa.no/</div>
                  <div class="txt">Nederst på skjermen har du dele symbolet - klikke på det.</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" order="2" order-md="1">
              <v-img contain :src="require('@/assets/home/start.png')" height="400" />
            </v-col>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12" md="8" order="1" order-md="2">
              <v-card flat>
                <v-card-text>
                  <div class="txt">
                    Når du har klikket på dele symbolet, får du opp en rekke valg, litt ned på denne listen finner du
                    "Legg til på Hjem-skjermen" - velg denne.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" order="2" order-md="1">
              <v-img contain :src="require('@/assets/home/del-hjem.png')" height="400" />
            </v-col>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12" md="8" order="1" order-md="2">
              <v-card flat>
                <v-card-text>
                  <div class="txt">
                    Når du har klikket på "Legg til på Hjem-skjermen" får du opp følgende bilde.
                  </div>
                  <div class="txt">
                    Trykk på "Legg til".
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" order="2" order-md="1">
              <v-img contain :src="require('@/assets/home/hjem.png')" height="400" />
            </v-col>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12" md="8" order="1" order-md="2">
              <v-card flat>
                <v-card-text>
                  <div class="txt">
                    Du finner nå "AtÅ - Elev" som et egen app på hjemskjerm. Etter første gangs pålogging, vil det ikke
                    være nødvendig å logge på neste gang.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" order="2" order-md="1">
              <v-img contain :src="require('@/assets/home/hjemskjerm.png')" height="400" />
            </v-col>
          </v-row>
          -->
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Homescreen',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.cardtitle {
  font-size: calc(1em + 1.2vmin);
  text-align: center;
  color: #4d4d4c;
}

.heading {
  font-size: calc(1.5em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 500;
  padding-bottom: 15px;
  padding-top: 10px;
}

.subheading {
  font-size: calc(1em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 10px;
}

.txt {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.75;
  text-align: center;
  font-weight: 400;
}

.txt1 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.25;
  text-align: left;
  font-weight: 300;
  padding-top: 20px;
}

.txt2 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 30px;
}

.txt3 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 20px;
}
</style>
