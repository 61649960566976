import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'en'
  },
  mutations: {
    setLanguage(state, lang) {
      state.lang = lang
    }
  },
  getters: {
    getLanguage(state) {
      return state.lang
    }
  },
  actions: {}
})
