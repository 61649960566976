<template>
  <v-container v-if="getLang() === 'no'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <h1 class="heading1">Hvordan det i virker.</h1>
              <p class="para">
                A til Å består av 2 forskjellige apper (nettsider) for elevene, en tjeneste for lærere, og en tjeneste
                for skoleleder. Disse kan legges til på hjemskjerm og vil da virke som en hvilken som helst app lastet
                ned fra appstore.
              </p>
              <p class="para2">
                Både lærer og elev logger på med sin Feide bruker, og får da automatisk oversikt over klasse og fag.
                Lærer har mulighet til å bestemme hvilke fag som skal være synlig for elevene. Det er ikke alltid det er
                nødendig at eleven ser alle fagene denne har. Vi anbefaler at lærerne samarbeider om hvilke fag som skal
                være <i>aktive</i> for elevene.
              </p>
              <p class="para2">
                For hvert fag som er aktivt vil vi samle inn noe informasjon fra elevene. Som representerer elevenes
                perspektiv. Vi samler inn informasjon om:
              </p>
              <ul>
                <li>Hvordan de mener at de bidrar til et godt læringsmiljø</li>
                <li>
                  Hvordan de bidrar til at alle trives på skolen, basert på spørsmål og problemstillinger rektor er
                  opptatt av
                </li>
                <li>Hva de er stolte av, fordi alle er god på noe</li>
                <li>Hva de har lært</li>
                <li>Hvordan de vurderer hvor mye de behersker av de læringsmålene som er definert av læreren</li>
                <li>Hvor mye tid de bruker på lekser - som vist i video under</li>
              </ul>
              <p class="para2">
                All informasjon fra elevene presenteres slik at den gir en rask oversikt over elevenes vurderinger.
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" align="stretch" class="pt-3">
            <v-col cols="12" sm="9" md="7" lg="6" xl="5">
              <v-toolbar color="#EEEEEE">
                <v-toolbar-title>
                  Registrering av tid elever bruker på lekser
                </v-toolbar-title>
              </v-toolbar>
              <iframe
                src="https://player.vimeo.com/video/415468595"
                frameborder="0"
                width="100%"
                height="400"
                allow="fullscreen"
                allowfullscreen
              >
              </iframe>
            </v-col>
          </v-row>
          <v-row justify="center" align="stretch" class="pt-3">
            <v-col cols="12" sm="9" md="7" lg="6" xl="5">
              <v-toolbar color="#EEEEEE">
                <v-toolbar-title>
                  Registrering av kompetansemål som er undervist
                </v-toolbar-title>
              </v-toolbar>
              <iframe
                src="https://player.vimeo.com/video/469735444"
                frameborder="0"
                width="100%"
                height="400"
                allow="fullscreen"
                allowfullscreen
              >
              </iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="getLang() === 'en'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <h1 class="heading1">How it works.</h1>
              <p class="para">
                We collect information from the learners using the "A to Z" app, and present this in a dashboard for the
                educators.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Works',
  data() {
    return {}
  },
  methods: {
    getLang() {
      // return this.$store.getters.getLanguage
      return 'no'
    }
  }
}
</script>

<style lang="scss" scoped>
.heading1 {
  font-size: calc(2rem + 1vmin);
  font-weight: 400;
  text-align: left;
}

.para {
  font-size: calc(0.8rem + 1vmin);
  font-weight: 400;
  text-align: left;
  margin-top: 1rem;
}
.para1 {
  font-size: calc(0.8rem + 1vmin);
  font-weight: 400;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
