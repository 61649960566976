import Vue from 'vue'
import Router from 'vue-router'
import LandingPage from './views/LandingPage.vue'
import Buy from './views/Buy.vue'
import Personvern from './views/Personvern.vue'
import Contact from './views/Contact.vue'
import About from './views/About.vue'
import UtLogget from './views/UtLogget.vue'
import Homescreen from './views/Homescreen.vue'
import HelpLearner from './views/HelpLearner.vue'
import HelpEducator from './views/HelpEducator.vue'
import HelpCurriculum from './views/HelpCurriculum.vue'
import Works from './views/Works.vue'
import Usage from './views/Usage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/personvern',
      name: 'Personvern',
      component: Personvern
    },
    {
      path: '/pris',
      name: 'Buy',
      component: Buy
    },
    {
      path: '/kontakt',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/om',
      name: 'About',
      component: About
    },
    {
      path: '/utlogget',
      name: 'UtLogget',
      component: UtLogget
    },
    {
      path: '/hjemskjerm',
      name: 'Homescreen',
      component: Homescreen
    },
    {
      path: '/elevhjelp',
      name: 'HelpLearner',
      component: HelpLearner
    },

    {
      path: '/laererhjelp',
      name: 'HelpEducator',
      component: HelpEducator
    },

    {
      path: '/usage',
      name: 'Usage',
      component: Usage
    },
    {
      path: '/curriculumhjelp',
      name: 'HelpCurriculum',
      component: HelpCurriculum
    },
    {
      path: '/virker',
      name: 'Works',
      component: Works
    },
    {
      path: '*',
      name: 'LandingPage',
      component: LandingPage
    }
  ]
})
