<template>
  <!-- NORWEGIAN -->
  <v-container v-if="getLang() == 'no'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="7" xl="4">
        <v-img contain position="center center" :src="require('@/assets/ataa-hypatia.svg')" />
      </v-col>
    </v-row>
    <v-row style="height: 50px;" />
    <v-row justify="center" align="center">
      <v-col cols="12" md="10" xl="6">
        <h1 class="heading">
          Kontakt
        </h1>
      </v-col>
    </v-row>
    <v-row height="450px" justify="center" align="center">
      <v-col cols="12" md="5" xl="3">
        <v-card height="450px">
          <v-card-text>
            <h1 class="heading"></h1>
            <p class="txt3">Nygaardsgaten 33, (Blender collective)</p>
            <p class="txt2">1606 Fredrikstad</p>
            <p class="txt2" href="tel://004790129642">+47 90129642</p>
            <p class="txt3">
              <a href="mailto:learning@hypatia.no">learning@hypatia.no</a>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" xl="3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2042.2570274791635!2d10.936063516771446!3d59.21171852748831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464403e3a4a2b98f%3A0x8a08de8f2ef8a6c3!2sBlender%20Collective!5e0!3m2!1sno!2sno!4v1569229966543!5m2!1sno!2sno"
          width="100%"
          height="450"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
        ></iframe>
      </v-col>
    </v-row>
  </v-container>

  <!-- ENGLISH -->
  <v-container v-else-if="getLang() == 'en'" fluid>
    <v-row justify="center" align="start">
      <v-col cols="12" sm="9" md="7" lg="6" xl="5">
        <v-img contain position="center center" :src="require('@/assets/atz-hypatia.svg')" />
      </v-col>
    </v-row>
    <v-row justify="center" align="start">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row style="height: 50px;" />
          <v-row justify="center" align="start">
            <v-col cols="12" sm="10" md="8" lg="7" xl="6">
              <h1 class="heading">
                Contact
              </h1>
            </v-col>
          </v-row>
          <v-row height="450px" justify="center" align="center">
            <v-col cols="12" sm="11" md="5" lg="4.5" xl="3">
              <v-card height="450px">
                <v-card-text>
                  <h1 class="heading"></h1>
                  <p class="txt3">Nygaardsgaten 33, (Blender collective)</p>
                  <p class="txt2">NO-1606 Fredrikstad<br />Norway</p>
                  <p class="txt2" href="tel://004790129642">+47 90129642</p>
                  <p class="txt3">
                    <a href="mailto:learning@hypatia.no">learning@hypatia.no</a>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="11" md="5" lg="4.5" xl="3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2042.2570274791635!2d10.936063516771446!3d59.21171852748831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464403e3a4a2b98f%3A0x8a08de8f2ef8a6c3!2sBlender%20Collective!5e0!3m2!1sno!2sno!4v1569229966543!5m2!1sno!2sno"
                width="600"
                height="450"
                frameborder="0"
                style="border:0;"
                allowfullscreen=""
              ></iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {}
  },
  methods: {
    getLang() {
      return this.$store.getters.getLanguage
    }
  }
}
</script>

<style lang="scss" scoped>
.cardtitle {
  font-size: calc(1em + 1.2vmin);
  text-align: center;
  color: #4d4d4c;
}

.heading {
  font-size: calc(1.5em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 500;
  padding-bottom: 50px;
  padding-top: 50px;
}

.txt {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 2;
  text-align: center;
  font-weight: 500;
  padding-bottom: 100px;
}

.txt1 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 12px;
  font-weight: 300;
}
.txt2 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 30px;
}

.txt3 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 20px;
}
</style>
