<template>
  <v-app>
    <v-app-bar app dark :src="require('@/assets/static/top-header.svg')" positon="bottom" flat>
      <v-menu offset-y offset-x>
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon dark v-on="on"> </v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn text v-if="getLang() === 'no'" @click="doAbout()">Om</v-btn>
              <v-btn text v-else-if="getLang() === 'en'" @click="doAbout()">About</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text v-if="getLang() === 'no'" @click="doUsage()">Hvordan bruke</v-btn>
              <v-btn text v-else-if="getLang() === 'en'" @click="doUsage()">Usage</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text v-if="getLang() === 'no'" @click="doBuy()">Kjøp</v-btn>
              <v-btn text v-else-if="getLang() === 'en'" @click="doBuy()">Buy</v-btn>
            </v-list-item-title>
          </v-list-item>
          <!--
        <v-list-item>
          <v-list-item-title>
            <v-btn text @click='doAbout()'>Om A til Å</v-btn>
          </v-list-item-title>
        </v-list-item>
      -->
          <v-list-item>
            <v-list-item-title>
              <v-btn text v-if="getLang() === 'no'" @click="doContact()">Kontakt</v-btn>
              <v-btn text v-else-if="getLang() === 'en'" @click="doContact()">Contact</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text v-if="getLang() === 'no'" @click="doWorks()">Hvordan det virker</v-btn>
              <v-btn text v-else-if="getLang() === 'en'" @click="doWorks()">How it works</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text v-if="getLang() === 'no'" @click="doPersonvern()">Personvernerklæring</v-btn>
              <v-btn text v-else-if="getLang() === 'en'" @click="doPersonvern()">Privacy declaration</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-group v-if="getLang() === 'no'" :value="false">
            <template v-slot:activator>
              <v-list-item-title>Logg på</v-list-item-title>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text href="https://elev.ataa.no/">Elev</v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text href="https://lekser.ataa.no/">Elev-Lekser</v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text href="https://l.ataa.no/">Lærer</v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text href="https://skole.ataa.no/">Skoleleder</v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>
        </v-list>
      </v-menu>

      <v-toolbar-title v-if="getLang() === 'no'" @click.stop="goHome()">A til Å - For bevisste elever</v-toolbar-title>
      <v-toolbar-title v-else-if="getLang() === 'en'" @click.stop="goHome()">
        A to Z - For concious learners
      </v-toolbar-title>
      <v-spacer />
      <v-btn text dark>
        {{ version }}
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import app from '../package.json'
export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      version: app.version
    }
  },
  methods: {
    doPersonvern() {
      //  this.activeMenyItem = 20
      this.$router.push({
        name: 'Personvern'
      })
    },
    goHome() {
      //  this.activeMenyItem = 20
      this.$router.push({
        name: 'LandingPage'
      })
    },
    showDrawer() {
      this.drawer = true
    },
    doUsage() {
      this.drawer = false
      this.$router.push({
        name: 'Usage'
      })
    },
    doBuy() {
      this.drawer = false
      this.$router.push({
        name: 'Buy'
      })
    },
    doAbout() {
      this.drawer = false
      this.$router.push({
        name: 'About'
      })
    },
    doWorks() {
      this.drawer = false
      this.$router.push({
        name: 'Works'
      })
    },
    doContact() {
      this.$router.push({
        name: 'Contact'
      })
      this.drawer = false
    },
    getLang() {
      return this.$store.getters.getLanguage
    }
  },
  mounted() {
    // do something after mounting vue instance
    const lang = navigator.language.substring(0, 2)
    console.log('===== LANGUAGE (%s)', lang)
    // 'nb-no', 'en-us', 'nn-no', 'nn', 'en-GB', 'es-ES', 'fr-FR', 'no', 'sv'
    let bl = 'no' // default
    switch (lang.toLowerCase()) {
      case 'nb':
        bl = 'no'
        break
      case 'no':
        bl = 'no'
        break
      case 'nn':
        bl = 'no'
        break
      case 'en':
        bl = 'en'
        break
      /*
      case 'es':
        bl = 'es'
        break
      case 'sv':
        bl = 'sv'
        break
      case 'fr':
        bl = 'fr'
        break
        */
      default:
        bl = 'en'
    }
    this.$store.commit('setLanguage', bl)
  }
}
</script>
