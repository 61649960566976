<template>
  <v-container v-if="getLang() === 'no'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="7" xl="4">
        <v-img contain :src="require('@/assets/ataa-hypatia.svg')" height="150" />
      </v-col>
    </v-row>
    <v-row justify="center" align="stretch" class="pt-3">
      <v-col cols="12" md="9" xl="6">
        <iframe
          src="https://player.vimeo.com/video/385660390"
          frameborder="0"
          width="100%"
          height="400"
          allow="fullscreen"
          allowfullscreen
        ></iframe>
      </v-col>
    </v-row>
    <v-row justify="center" align="start" class="pt-3">
      <v-col cols="12" md="9" xl="6">
        <p class="txt1">
          A til Å (ataa) er en nettbasert løsning som bevisstgjør elever sitt bidrag til læringsmiljø i klassen. Elevene
          blir også bevisstgjort hvordan de bidrar til skolemiljøet. Elevene må også reflekterer over egen læring. Denne
          informasjonen presenteres på en oversiktlig måte for læreren.
        </p>
        <p class="txt1">
          Vi tror at en bevisstgjøring av elever vil ha positive effekter på skolemiljø og læringsmiljø.
        </p>
        <ul class="list">
          <li class="litem">Som mindre mobbing</li>
          <li class="litem">Økt trivsel hos alle elever</li>
          <li class="litem">Bedre læringsmiljø og arbeidsro i klassene</li>
        </ul>
        <p class="txt1">
          Lærer har full kontroll på hvilke fag elevene ser.
        </p>
        <p class="txt1">
          Lærer har et "dashboard" som gir en oversikt over hva elevene lært, hvordan de elevne bidrar til læringsmiljø
          i klassen, hvordan de arbeider med skolearbeid og hvordan de vurdere sin arbeidsinnsats i hvert enkelt fag.
        </p>
        <p class="txt1">
          Rektor får en samlet oversikt elevenes vurdering av skolemiljø.
        </p>
        <p class="txt1">
          A til Å bruker Feide pålogging.
        </p>
      </v-col>
    </v-row>
    <v-row style="height: 50px;" />
    <v-row justify="center" align="baseline">
      <v-col cols="12" md="3" xl="2">
        <v-card flat>
          <v-img :src="require('@/assets/static/elev.png')">
            <v-card-title pl-3 class="cardtitle">Elev</v-card-title>
          </v-img>
          <v-card-text>
            <p class="txt1">
              Elevene må selv vurdere hvordan de har bidratt til læringsmiljø, ved å vurdere sin innstats i forhold til
              de "kjøreregler" klassen har blitt enige om. Det er en lavterskelkanal hvor elevene gir en kontinuerlig
              tilbakemelding på hvordan de opplever læringsmiljøet i klassen.
            </p>
            <p class="txt1">
              Elevene kan også svare på spørsmål skoleledelsen stiller om skolemiljø, for å bevisstgjøre elevene om at
              hver enkelt har et ansvar for å bidra til at alle har et godt skolemiljø.
            </p>
            <p class="txt1">
              Avhengig av hvilket klassetrinn eleven er på, ber vi eleven reflektere over hva de har lært, og hvordan de
              kan anvende det de har lært.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xl="2">
        <v-card flat>
          <v-img class="white--text" :src="require('@/assets/static/teacher.png')">
            <v-card-title class="cardtitle">Lærer</v-card-title>
          </v-img>
          <v-card-text>
            <p class="txt1">
              Kontaktlærer får oversikt over klassens vurdering av skolemiljø, elevenes bidrag til læringsmiljø, hva de
              har lært, og hvilke fag eleven skal se i sin løsning.
            </p>
            <p class="txt1">
              Lærere får en enkel oversikt over alle elevenes vurderinger.
            </p>
            <p class="txt1">
              Lærer har full kontroll på hvilke fag elevene skal se og vurdere sin innsats og læring i.
            </p>
            <p class="txt1">
              Tilbakemeldinger og vurderinger fra elevenes perspektiv er et godt utgangspunkt for tilpasning av
              undervisningen til elevene. Tilbakemeldinger fra elevene blir en kontinuerlig underveisevaluering.
            </p>
            <p class="txt1">
              Elevenes egenvurderinger er også et godt utgangspunkt for samtaler med elevene.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xl="2">
        <v-card flat>
          <v-img class="white--text" :src="require('@/assets/static/skole.png')">
            <v-card-title class="cardtitle">Skoleleder</v-card-title>
          </v-img>
          <v-card-text>
            <p class="txt1">
              Skoleleder kan definere hvilke spørsmål elevene skal svare på i forhold til skolemiljø.
            </p>
            <p class="txt1">
              Skoleleder får en samlet oversikt over hvordan elevene opplever skolemiljøet.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {}
  },
  methods: {
    getLang() {
//      return this.$store.getters.getLanguage
return 'no'
    }
  }
}
</script>

<style lang="scss" scoped>
.cardtitle {
  font-size: calc(1em + 1.2vmin);
  text-align: center;
  color: #4d4d4c;
}

.heading {
  font-size: calc(1.5em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 500;
  padding-bottom: 50px;
  padding-top: 100px;
}

.txt {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.75;
  text-align: center;
  font-weight: 500;
}

.txt1 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 12px;
  font-weight: 300;
}
.txt2 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 30px;
}

.txt3 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 300;
  padding-bottom: 20px;
}

.litem {
  font-size: calc(0.6em + 0.4vmin);
  font-weight: 300;
  text-align: left;
  margin-bottom: 0px;
}

.list {
  padding-bottom: 12px;
  margin-left: 25px;
}
</style>
