<template>
  <!-- NORWEGIAN -->
  <v-container v-if="getLang() === 'no'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="10">
        <v-img contain :src="require('@/assets/ataa-hypatia.svg')" height="150" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="10">
        <p class="txt4">
          Veiledning for hvordan legge våre løsninger som en app på nettbrett og smarttelefon.
          <br />
          <v-btn @click="doHomescreen()" large icon color="blue">
            <v-icon large>ondemand_video</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>

    <v-row justify="center" align="start">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <h1 class="heading">
          Trykk på tjeneste under for å logge på.
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" align="start">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-card-text>
                  <v-img contain :src="require('@/assets/static/d-learner.svg')" height="150" />
                </v-card-text>
                <v-card-actions>
                  <v-btn text outlined rounded target="_blank" href="https://elev.ataa.no/" width="50%">Elev</v-btn>
                  <v-btn color="green lighten-1" text outlined rounded href="https://lekser.ataa.no/" width="50%">
                    Hjemmearbeid
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-card-text>
                  <v-img contain :src="require('@/assets/static/d-teacher.svg')" height="150" />
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="green lighten-1"
                    text
                    outlined
                    rounded
                    target="_blank"
                    href="https://l.ataa.no/"
                    width="100%"
                  >
                    Lærer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-card-text>
                  <v-img contain :src="require('@/assets/static/school.png')" height="150" />
                </v-card-text>
                <v-card-actions>
                  <v-btn text outlined rounded target="_blank" href="https://skole.ataa.no/" width="100%">
                    Skoleleder
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-show="displayVideo" justify="center" align="center" class="delay1 pt-4">
            <v-col cols="12" sm="11" md="10" lg="9" xl="8">
              <!-- <template>
                <vimeo-player
                  player-widht="100%"
                  ref="player"
                  video-id="385660390"
                ></vimeo-player>
              </template>
              -->
              <iframe
                src="https://player.vimeo.com/video/385660390"
                frameborder="0"
                width="100%"
                height="400"
                allow="fullscreen"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>
          <transition name="fade">
            <v-row v-show="displayText" justify="center" align="center" class="delay2 mt-4">
              <v-col cols="12" sm="11" md="10" lg="9" xl="8">
                <p class="txt5">
                  A til Å (ataa) er en nettbasert løsning hvor elever vurderer sitt eget bidrag til et godt læringsmiljø
                  i klassen og på skolen og reflekterer over egen læringsprosess ved å gi tilbakemelding på egen innsats
                  i de fagene de har.
                </p>
                <p class="txt5">
                  Løsningen bruker nye Feide. Elever får automatisk oversikt over sine fag, lærere får i tillegg
                  oversikt over sine klasser.
                </p>
                <p class="txt5">
                  Skoleleder får en samlet oversikt elevenes vurdering av skolemiljø, og hvilke kompetansemål som er
                  undervist.
                </p>
              </v-col>
            </v-row>
          </transition>
        </v-container>
      </v-col>
    </v-row>
  </v-container>

  <!-- ENGLISH -->
  <v-container v-else-if="getLang() === 'en'" fluid>
    <v-row justify="center" align="start">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="10" md="8" lg="7" xl="6">
              <iframe
                src="https://player.vimeo.com/video/385660491"
                frameborder="0"
                width="100%"
                height="400"
                allow="fullscreen"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="10" md="8" lg="7" xl="6">
              <div class="txt">
                A to Z is a tool for students to assess their own efforts in their different subjects, and how they view
                their contribute to the learning environment.
              </div>
            </v-col>
          </v-row>
          <v-row style="height: 50px;" />
          <v-row justify="center" align="start">
            <v-col cols="12" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-img :src="require('@/assets/static/elev.png')">
                  <v-card-title pl-3 class="cardtitle">Student</v-card-title>
                </v-img>
                <v-card-text>
                  <div class="txt1">
                    The teacher uses the tool to ask the student how their psychosocial environment are, and how they
                    contribute to the learning environment in their classes. The student then need to do a
                    selfassessment about their contribution to the learning environment. This would give the teacher a
                    more detailed view on how the individual learners see their daily life at the school.
                  </div>
                  <div class="txt1">
                    Depending on the age level of the students, they are also required to describe what they have
                    learned, what they have done and what they remember from the last week - in each subject.
                  </div>
                  <div class="txt1">
                    The students could also on a daily basis indicate how much time they have used on homwork in each
                    subject.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-img class="white--text" :src="require('@/assets/static/teacher.png')">
                  <v-card-title class="cardtitle">Teacher</v-card-title>
                </v-img>
                <v-card-text>
                  <div class="txt1">
                    The teacher have a dashboard where all of the students self-assessments are displayed.
                  </div>
                  <div class="txt1">
                    Using this tool, the teacher could use the students self assessment as a comparison with his own
                    view, and if there are any discrepancies maybe a chat with the student would be in order.
                  </div>
                  <div class="txt1">
                    The students perspective is also a good starting point for conversations with the student and
                    parents.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-img class="white--text" :src="require('@/assets/static/skole.png')">
                  <v-card-title class="cardtitle">School leaders</v-card-title>
                </v-img>
                <v-card-text>
                  <div class="txt1">As a school leader you:</div>
                  <div class="txt1">
                    Define the criteria used for assessing the psychosocial learning environment.
                  </div>
                  <div class="txt1">
                    Define the questions to be asked to the student.
                  </div>
                  <div class="txt1">
                    Get a dashboard illustrating the psychosocial environment of the learners.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12">
              <div class="header">
                Click on buttons below to login
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="4" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-card-text>
                  <v-img contain :src="require('@/assets/static/d-learner.svg')" height="150" />
                </v-card-text>
                <v-card-actions>
                  <v-btn text outlined rounded target="_blank" href="https://elev.ataa.no/" width="100%">Student</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-card-text>
                  <v-img contain :src="require('@/assets/static/d-teacher.svg')" height="150" />
                </v-card-text>
                <v-card-actions>
                  <v-btn text outlined rounded target="_blank" href="https://l.ataa.no/" width="100%">Teacher</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="3" xl="2">
              <v-card flat>
                <v-card-text>
                  <v-img contain :src="require('@/assets/static/school.png')" height="150" />
                </v-card-text>
                <v-card-actions>
                  <v-btn text outlined rounded target="_blank" href="https://skole.ataa.no/" width="100%"
                    >Principal</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row height="600px" justify="center" align="center">
            <v-col cols="12" sm="12" md="6" lg="4.5" xl="3">
              <v-card height="600px">
                <v-card-text>
                  <div class="heading">Contact</div>
                  <div class="txt3">Nygaardsgaten 33, (Blender collective)</div>
                  <div class="txt2">NO-1606 Fredrikstad</div>
                  <div class="txt2" href="tel://004790129642">+47 90129642</div>
                  <div class="txt3">
                    <a href="mailto:learning@hypatia.no">learning@hypatia.no</a>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4.5" xl="3" class="hidden-sm-and-down">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2042.2570274791635!2d10.936063516771446!3d59.21171852748831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464403e3a4a2b98f%3A0x8a08de8f2ef8a6c3!2sBlender%20Collective!5e0!3m2!1sno!2sno!4v1569229966543!5m2!1sno!2sno"
                width="600"
                height="600"
                frameborder="0"
                style="border:0;"
                allowfullscreen=""
              ></iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LandingPage',
  data() {
    return {
      displayVideo: false,
      displayText: false,
      displayHeader: false
    }
  },
  methods: {
    getLang() {
      return this.$store.getters.getLanguage
    },
    showHeader() {
      this.displayHeader = true
    },
    showVideo() {
      this.displayVideo = true
    },
    showText() {
      this.displayText = true
    },
    doHomescreen() {
      this.$router
        .push({
          name: 'Homescreen'
        })
        .catch(() => {})
    },

    delayVisibility() {
      setTimeout(this.showVideo, 4000)
      setTimeout(this.showHeader, 3500)
      setTimeout(this.showText, 5500)
    }
  },
  mounted() {
    console.log('==== MOUNTED GOT CALLED =======')
    this.delayVisibility()
  }
}
</script>

<style lang="scss" scoped>
.cardtitle {
  font-size: calc(1em + 1.2vmin);
  text-align: center;
  color: #4d4d4c;
}

.heading {
  font-size: calc(1em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 500;
  padding-bottom: 10px;
}

.subheading {
  font-size: calc(1.2em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 500;
  padding-bottom: 50px;
  padding-top: 100px;
}

.txt {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.75;
  text-align: center;
  font-weight: 500;
}

.txt1 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 12px;
  font-weight: 300;
}
.txt2 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 30px;
}

.txt3 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 300;
  padding-bottom: 20px;
}

.txt4 {
  font-size: calc(0.8em + 0.6vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 300;
  line-height: 1.25;
  padding-bottom: 20px;
}
.txt5 {
  font-size: calc(0.8em + 0.6vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 10px;
}

.fade-enter-active {
  transition: opacity 1.5s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  transition: opacity 1.5s;
}
</style>
