<template>
  <v-container v-if="getLang() === 'no'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <h1 class="heading1">Hvordan bruke A til Å for best effekt på læring og miljø</h1>
              <h2 class="heading2">Læringsmiljø i klassen</h2>
              <p class="para1">
                Vi bruker også smilefjes for å hjelpe elevene i å vurdere sitt bidrag til et godt læringsmiljø i sin
                klasse. Elevene kan velge å vurdere sitt bidrag til å være
                "passe"<v-icon>sentiment_dissatisfied</v-icon>, "godt"<v-icon>sentiment_satisfied</v-icon> eller
                "perfekt"<v-icon>sentiment_very_satisfied</v-icon>.
              </p>
              <p class="para">
                Skal vi gjøre elevene mer bevisste om hvordan de selv bidrar til et godt læringsmiljø, er et vikig at
                elevene selv blir enige om hvilke kriterier som gjelder for en passe, godt og en perfekt
                læringsomgivelse. Det er kriteriene elevene selv har blitt enige om, de skal bruke når de vurderer sitt
                bidrag til at alle har et godt læringsiljø.
              </p>
              <p class="para">
                I begynnelsen kan det være lurt om lærer og elevene sammen diskuterer hvordan læringsmiljøet har vært,
                når elevene vurdere sin egen innsats.
              </p>
              <h3 class="heading3">Eksempel på kriterier fra en klasse</h3>
              <v-img contain :src="require('@/assets/static/social-criteria.png')" width="75vw" />
              <figcaption>Kriterier brukt i en 6.klasse.</figcaption>
              <v-container fluid>
                <v-row align="start" justify="center">
                  <v-col cols="12" md="4">
                    <ul>
                      <lh>Passe læringsiljø</lh>
                      <li>Snakke uten å få ordet</li>
                      <li>Snakke i munnen på noen</li>
                      <li>Ikke følge med</li>
                      <li>Baksnakking</li>
                      <li>Gi negative kommentarer</li>
                      <li>Utestenging</li>
                      <li>Himle med øynene/Blikking</li>
                      <li>Vandre rundt</li>
                      <li>Forlate klassen uten lov</li>
                      <li>Rote</li>
                      <li>Ødelegge</li>
                      <li>Erte, plage eller slå</li>
                      <li>Bruk av sosiale medier</li>
                    </ul>
                  </v-col>
                  <v-col cols="12" md="4">
                    <ul>
                      <lh>Godt læringsiljø</lh>
                      <li>Rekke opp hånda</li>
                      <li>Arbeidsro</li>
                      <li>Følge beskjeder</li>
                      <li>Hjelpe de som trenger hjelp</li>
                      <li>Si hei</li>
                      <li>Ha blikkontakt</li>
                      <li>Smile være blid</li>
                      <li>Gå til noen som er alene</li>
                      <li>Være med flere</li>
                      <li>Være en god venn</li>
                      <li>Samarbeide</li>
                      <li>God hygiene</li>
                    </ul>
                  </v-col>
                  <v-col cols="12" md="4">
                    <ul>
                      <lh>Perfekt læringsiljø</lh>
                      <li>Venter på ordet</li>
                      <li>Rekker opp hånda</li>
                      <li>Følger med</li>
                      <li>Deltar</li>
                      <li>Gjør sitt aller beste</li>
                      <li>Hilse, være høflig</li>
                      <li>Være ærlig</li>
                      <li>Inkludere</li>
                      <li>Få andre til å føle seg bra</li>
                      <li>Spørre, få med de som lett blir glemt</li>
                      <li>Si hyggelige ting, fremsnakke</li>
                      <li>Vi lytter til hverandre</li>
                      <li>Ikke bli fort sur</li>
                      <li>Samarbeide</li>
                    </ul>
                  </v-col>
                </v-row>
              </v-container>
              <p class="para">Dette er kun noen eksempler på kriterier som kan brukes.</p>

              <h2 class="heading2">Skolemiljø</h2>
              <p class="para1">
                Skoleledelsen kan selv stille spørsmål som bidrar til økt fokus og bevissthet på hva som gjør et godt
                skolemiljø. Dette er perspektiver alle elevene på skolen kan forholde seg til.
              </p>
              <p>
                I perioder med hjemmeundervisning kan det f.eks. være lurt å stille spørsmål om hvordan alle elever
                opplever forskjellige aspekter ved hjemmeundervisning.
              </p>
              <p>
                Mange pleier å hente spørsmål fra eleveundersøkelsen, som utgangspunkt på aspekter ved
                hjemmeundervisningen, skolen ønsker å bevisstgjøre elevene rundt.
              </p>
              <p>
                Vi vil anbefale at elevene ikke får mer enn 3-4 spørsmål å forholde seg til hver uke. Spørsmålene vil gi
                skoleledelsen et oppdatert perspektiv på hvordan elevene opplever skolemiljø.
              </p>
              <p>
                Vi har bevisst valgt å ikke gi skoleledelse og lærer oversikt over hva hver enkelt elev svarer på
                spørsmål om skolemiljø. Skolemiljø er et kollektivt ansvar, og hvis noen elever opplever at skolemiljøet
                ikke er bra, så er dette alles ansvar.
              </p>
              <p>
                Resultatene fra skolemiljø er synlig for skoleledelse, hvor de ser gjenomsnittverdien for hele skolen,
                og for hver enkelt klasse. Kontaktlærer ser kun informasjon om sin egen klasse.
              </p>
              <h2 class="heading2">Fag og læringsmål</h2>
              <p>
                Som faglærer kan du velge å gjøre faget ditt tilgjengelig for vurdering hos eleven. Kontaktlærer kan
                hvilke fag som skal være synlig for elevene i klassen. Vi vil anbefale at noen av fagene ruleres for at
                ikke listen elevene skal forholde seg til blir for omfattende.
              </p>
              <p>
                For de fagene der du er faglærer, kan du hvilke kompetansemål som gjelder i faget. Mange av disse
                kompetansemålene kan det være vanskelig for elever å forstå, du kan derfor velge å skrive om et
                kompetansemål i flere læringsmål som elevene kan forstå og forholde seg til. Du kan også velge hvilke
                uker læringsmålene skal være synlig for elevene, slik at de kan angi hvor godt de selv mener de
                "behersker" læringsmålet.
              </p>
              <p>
                I hvert fag, ber vi også elevene om å angi hvor godt de selv mener de jobber i faget, dette gjøres på en
                glidende skala fra "passe"<v-icon>sentiment_dissatisfied</v-icon>, "godt"<v-icon
                  >sentiment_satisfied</v-icon
                >
                eller "veldig bra"<v-icon>sentiment_very_satisfied</v-icon>. Som lærer får en oversikt om hvordan
                arbeidsinnsatsen til elevene endrer seg over tid.
              </p>
              <p>
                Vi ber også elevene beskrive hva de har lært i hvert enkelt fag. Det å beskrive hva du har lært i et fag
                i løpet av en dag, eller en uke er veldig vanskelig. Det å beskrive dette bidrar til dybdelæring og en
                bevisstgjøring av hva elevene gjør på skolen, og hvorfor. Som lærer får du opp en ordsky over de ordene
                elevene har brukt for å beskrive hva de har lært. Og forhåpentligvis vil denne ordsky stemme overens med
                de ordene du ønsker at elevene skal bruke når de forklarer hva de har lært.
              </p>
              <h2 class="heading2">Hvordan bruke informasjon du som lærer får</h2>
              <p>
                Som lærer får du med A til Å en oversikt over hvordan eleven selv vurderer egen innsats, trivsel og hva
                de lærer. Hvis elevens perspektiv på egen læring og aktivitet er motstridende med det inntrykk du som
                lærer har, er det et godt utgangspunkt for en dialog med eleven.
              </p>
              <p>
                Hvordan du som lærer velger å bruke den innsikt du får om elevenes perspektiv på egen læring, bidrag til
                læringsmiljø mm. kan ikke vi påvirke. A til Å er ikke laget for evaluere elevene, dette er ikke et
                verktøy for evaluering av elevene, A til å er et verktøy for å gi deg som lærer elevens perspektiv.
              </p>
              <h3 class="heading3">Utfordringen til deg som lærer</h3>
              <p>
                Vi ser fra bruk av A til Å, at læreren får en ny innsikt om elevenes læring, hva de lærer, og hva de
                oppfatter som viktig av hva de har lært. Hvis du som lærer gjennom A til Å får tilbakemeldinger på at
                elevene ikke lærer hva du har tenkt, eller at de har misforstått viktige momenter, bør dette gis som
                tilbakemelding til klassen. Vi tror en god dialog mellom lærer og eleve er viktig for god læring. Vi
                håper A til Å bidrar til en god dialog mellom lærer og elever om deres læring.
              </p>
              <p>
                Det å vise elevene hva de skal lære av kompetansemål og tilpasning av disse i ett eller flere
                læringsmål, kan ha en god effekt på elevene. Dette gir elevene en god oversikt over hva de skal lære i
                løpet av et år, eller i løpet av den perioden læreplanen gjelder. Hvordan dette presenteres og forklares
                for elevene kan være utfordrende, elevene har selv mulighet i sine verktøy til å se alle
                kompetansemålene.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="getLang() === 'en'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <h1 class="heading1">Usage</h1>
              <p class="para1">Process description for how to use A to Z for best effect..</p>
              <p class="para">Involve the learners, be transparent.</p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Usage',
  data() {
    return {}
  },
  methods: {
    getLang() {
      // return this.$store.getters.getLanguage
      return 'no'
    }
  }
}
</script>

<style lang="scss" scoped>
.heading1 {
  font-size: calc(2rem + 2vh);
  font-weight: 500;
  text-align: left;
}
.heading2 {
  font-size: calc(1.5rem + 2vh);
  margin-top: 5vh;
  font-weight: 400;
  text-align: left;
}
.heading3 {
  font-size: calc(0.6rem + 2vh);
  font-weight: 300;
  text-align: left;
}

lh {
  font-size: calc(0.5rem + 2vh);
  font-weight: 600;
  text-align: left;
}

li {
  font-size: calc(0.5rem + 1.2vh);
}

figcaption {
  font-size: calc(0.3rem + 1vh);
  font-weight: 300;
}

p,
.para {
  font-size: calc(0.6rem + 1vh);
  font-weight: 400;
  text-align: left;
  margin-top: 2vh;
}
.para1 {
  font-size: calc(0.6rem + 1vh);
  font-weight: 400;
  text-align: left;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
</style>
