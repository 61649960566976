<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6" xl="4">
        <v-img contain position="center center" :src="require('@/assets/ataa-hypatia.svg')" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="10" xl="6">
        <h1 class="heading">
          Personvernerklæring
        </h1>
        <p class="txt1">
          Alle tjenester utviklet av Hypatia Learning AS, baseres på at skoleeier kan forvalter all informasjon
          produsert av elevene. Skoleeier bestemmer selv om informasjonen skal forvaltes i kommunens skytjenester.
        </p>
        <p class="txt1">
          Det betyr at skoleeier er ansvarlig for å forvalte og administrere de data som produseres i våre løsninger.
        </p>
        <p class="txt1">
          Det betyr også at det ikke er behov for noen databehandleravtale mellom Hypatia Learning AS og skoleeier, da
          Hypatia Learning AS ikke lagrer eller behandler noe data. Med unntak i de tilfeller hvor Hypatia Learning
          forvalter elevprodusert informasjon på vegne av skoleeierne.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="10" xl="6">
        <h1 class="heading">
          Hva lagres i løsningen
        </h1>
        <p class="txt1">
          ATAA som tjeneste får tilgang til noe persondata når du logger inn. Første gang du logger inn må du gi
          samtykke til dette igjennom Feide.
        </p>
        <p class="txt1">
          ATAA løsningen har tre brukergrupper som beskrevet under, hvor forskjellige informasjon registreres.
        </p>
        <p class="txt1">
          All informasjon som produseres av våre løsninger kan lagres der skoleeier bestemmer.
        </p>
      </v-col>
    </v-row>

    <v-row justify="center" align="baseline">
      <v-col cols="12" md="5" xl="3">
        <v-card flat>
          <v-img :src="require('@/assets/static/elev.png')">
            <v-card-title class="cardtitle">Elev</v-card-title>
          </v-img>
          <v-card-text>
            <p class="txt1">
              Vi lagrer noen av attributtene vi får fra Feide, som navn, FEIDE-ID, bilde, rolle (Student), tilhørighet,
              grupper som skoleklasse og hvilke fag eleven har.
            </p>
            <p class="txt1">
              Ellers lagrer vi den informasjon som produseres av eleven i løsningen, som hvordan eleven vurderer sin
              egen innsats til godt skolemiljø, og hva elevene har lært i det enkelte fag osv.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" xl="3">
        <v-card flat>
          <v-img :src="require('@/assets/static/teacher.png')">
            <v-card-title class="cardtitle">Lærer</v-card-title>
          </v-img>
          <v-card-text>
            <p class="txt1">
              Vi lagrer noen av de attributtene vi får fra Feide, som navn, FEIDE-ID, bilde, roller (Lærer),
              tilhørighet, grupper hvor du er kontaktlærer, og fag du underviser.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row height="500px" justify="center" align="center">
      <v-col cols="12" md="5" xl="3">
        <v-card height="500px">
          <v-card-text>
            <p class="heading">Kontakt:</p>
            <p class="txt3">Nygaardsgaten 33, (Blender collective)</p>
            <p class="txt2">1606 Fredrikstad</p>
            <p class="txt2" href="tel://004790129642">+47 90129642</p>
            <p class="txt3">
              <a href="mailto:personvern@hypatia.no">personvern@hypatia.no</a>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" xl="3" class="hidden-sm-and-down">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2042.2570274791635!2d10.936063516771446!3d59.21171852748831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464403e3a4a2b98f%3A0x8a08de8f2ef8a6c3!2sBlender%20Collective!5e0!3m2!1sno!2sno!4v1569229966543!5m2!1sno!2sno"
          width="100%"
          height="500"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'C1',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.cardtitle {
  font-size: calc(1em + 1.2vmin);
  text-align: center;
  color: #4d4d4c;
}

.heading {
  font-size: calc(1.5em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  font-weight: 500;
  padding-bottom: 50px;
  padding-top: 100px;
}

.txt {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.75;
  text-align: center;
  font-weight: 500;
}

.txt1 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  line-height: 1.25;
  text-align: left;
  font-weight: 300;
  padding-top: 20px;
}

.txt2 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 30px;
}

.txt3 {
  font-size: calc(0.8em + 1vmin);
  color: #4d4d4c;
  text-align: center;
  line-height: 1.25;
  font-weight: 300;
  padding-bottom: 20px;
}
</style>
