import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/'
// import store from '@/store'
import '@/registerServiceWorker'
import vuetify from './plugins/vuetify'

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  Vue.config.devtools = false
  Vue.config.productionTip = false
} else {
  // require('vue-devtools').install()
  console.log('>>>>>>>>>>> WE HAVE DEVELOPMENT  <<<<<<<<<<<< ')
  Vue.config.devtools = true
  Vue.config.productionTip = true
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
