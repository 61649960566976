<template>
  <v-card>
    <v-toolbar :color="helpColor()" lighten>
      <v-toolbar-title v-if="getLang() === 'no'">Hjelp i bruk av A til Å for lærere.</v-toolbar-title>
      <v-toolbar-title v-if="getLang() === 'en'">Help in use of AtoZ for educators.</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="getLang() === 'no'" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Miljø</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Her er en oversikt over hvordan gjennomsnittet i klassen vurderer sitt eget bidrag til klassemiljøet.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/miljo-1.png')" height="200" />
            <v-card-text>
              Ved å trykke på pilen, kommer den enkelte elevs vurdering opp i nedtrekksmenyen
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/miljo-2.png')" height="200" />
            <v-card-text>
              Ved å trykke på statistikkikonet, kommer en statistisk fremstilling av elevenes vurdering av eget bidrag i
              en gitt periode.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/miljo-3.png')" height="200" />
            <v-card-text>
              Dette er en oversikt over hva elevene har meldt tilbake i en gitt periode.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/miljo-4.png')" height="200" />
            <v-card-text>
              Dette er en generell oversikt over hvordan klassen som helhet svarer på spørsmål om skolemiljø i en gitt
              periode.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/miljo-5.png')" height="200" />
            <v-card-text>
              Ved å trykke på statistikkikonet, kommer en statistisk fremstilling av elevenes vurdering av eget bidrag i
              en gitt periode.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/miljo-6.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Fag</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Dette er en visuell fremstilling av hvordan elevene vurderer sin egen grad av måloppnåelse. Hver sirkel i
              diagrammet representerer en elev.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/fag-1.png')" height="200" />
            <v-card-text>
              Ordskyen viser hvilke ord og begrep som er gjentatt flest ganger i elevenes tilbakemelding om hva de har
              lært i faget i en gitt periode.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/fag-2.png')" height="200" />
            <v-card-text>
              Oversikten viser hvordan gjennomsnittet i klassen vurderer sin egen innsats i faget eller hvordan de selv
              syns at de har jobbet med faget.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/fag-3.png')" height="200" />
            <v-card-text>
              Ved å trykke på nedtrekksmenyen, kommer enkeltelevenes vurdering frem.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/fag-4.png')" height="200" />
            <v-card-text>
              Hvordan elevene vurderer egen innsats i faget kan ses statistisk ved å trykke på statistikkikonet.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/fag-5.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Hjemmearbeid</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Tid brukt på lekser i de forskjellige fagene som elevene har registret i lekseappen.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/hjemme-1.png')" height="200" />
            <v-card-text>
              Tid brukt på lesing eller regning som elevene har registrert.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/hjemme-2.png')" height="200" />
            <v-img contain :src="require('@/assets/help/larer/hjemme-3.png')" height="200" />
            <v-img contain :src="require('@/assets/help/larer/hjemme-4.png')" height="200" />
            <v-img contain :src="require('@/assets/help/larer/hjemme-5.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Elev</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Oversikt over fagene du underviser og klassene du er hvor du er kontaktlærer. Når det står et tall bak
              faget, betyr det at det er registret en melding fra en elev.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/elev-1.png')" height="200" />
            <v-card-text>
              Som kontaktlærer kan du se alle fagene til elvene i klassen. Ved å trykke på nedtrykksmenyen, kommer
              enkeltelevene med deres fag opp.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/elev-2.png')" height="200" />
            <v-card-text>
              Her ligger elvenes tilbakemelding i faget på hva de har lært, hva de har gjort, osv.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/elev-3.png')" height="200" />
            <v-card-text>
              Faglærer får oversikt over tilbakemeldinger fra elevene i fagene du underviser. En blå sirkel bak
              fagkoden, betyr at det er uleste meldinger fra elever. Ved å åpne listen, ser du hvem som har sendt
              melding.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/elev-4.png')" height="200" />
            <v-card-text>
              Ved å huke av i høyre hjørne i meldingsboksen, markeres meldingen om lest.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/elev-5.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Tilpasninger</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Ved å huk av til høyre for fagene, bestemmer du hvilke fag som skal være synlige for elevene.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/tilpass-1.png')" height="200" />
            <v-card-text>
              Ved å trykke på faget, kommer en liste over alle kompetansemålene i faget slik de fremkommer i læreplanen.
              Dette hentes automatisk.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/tilpass-2.png')" height="200" />
            <v-card-text>
              Ved å trykke på det enkelte målet, kan du tilpasse det til det som skal jobbes med i en gitt periode som
              markeres i form av uketall. Her kan man fylle ut hva man ønsker at eleven skal se av det som skal læres,
              hva som skal gjøres, etc. Ved å trykke på lagringsikonet, kommer dette opp på siden til elevene du har i
              faget. Om du ønsker å bryte ned samme kompetansemål i flere delmål, trykker du på +-tegnet i den røde
              sirkelen.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/tilpass-3.png')" height="200" />
            <v-card-text>
              Når klassen har blitt enige om hva de anser som gode kriterier for et godt eller ikke så godt
              læringsmiljø, kan du registrere dem slik at de kommer frem på elevenes side.
            </v-card-text>
            <v-img contain :src="require('@/assets/help/larer/tilpass-4.png')" height="200" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- ** LANGUAGE ** ENGLISH **  -->
    <v-container v-if="getLang() === 'en'" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="10">
          <v-card>
            <v-toolbar :color="subjectColor()" lighten>
              <v-toolbar-title>Help in using our systems</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Please contact us at learner@hypatia.no for instructions on how to use our services.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: 'HelpEducator',
  data() {
    return {}
  },
  methods: {
    getLang() {
      return this.$store.getters.getLanguage
    },
    helpColor() {
      return 'blue darken-1'
    },
    subjectColor() {
      return 'blue lighten-3'
    }
  }
}
</script>
<style lang="scss" scoped></style>
