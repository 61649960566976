<template>
  <v-container v-if="getLang() === 'no'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <h1 class="heading1">Fra kunnskapsmål til læringsmål.</h1>
              <p class="para">
                Her kan du som lærer gjøre om kompetansemål fra UDIR til et læringsmål elevene selv forstår. Du kan også
                legge inn relevant informasjon om hvilke andre aspekter som er viktige for læringsmålet.
              </p>
              <p class="para2">Du kan lage så mange læringsmål du ønsker med utgangspunkt i kompetansemålet.</p>
              <p class="para2">
                Du bestemmer også hvilke uker læringsmålet skal være synlig for elevene. Når læringsmålet er synlig for
                elevene vil de selv kunne vurdere hvor mye av læringsmålet de selv mener at de behersker. Elevenes
                subjektive vurdering av hvor mye de behersker av læringsmålet presenteres på en enkel og oversiktlig
                måte. Med utgangspunkt i elevenes informasjon får du en oversikt over om noen av elevene begynner å
                falle etter i den faglige utviklingen. Dette er informasjon du kan bruke som utgangspunkt i en samtale
                med elevene.
              </p>
              <p class="para2">
                Som lærer får du også støtte fra veiledningsmateriale fra UDIR om hvilke verb, kjerneelementer,
                grunnleggende elementer og hva kunnskapsmålet bygger på.
              </p>
              <p class="para2">
                Som kontaktlærer kan du avgjøre hvilke fag som skal være synlig for elevene i den app de bruker for å
                informere deg om tid de bruker på lekser, og hvilke fag de skal vurdere læringsmålene.
              </p>
              <p class="para2">
                Du kan også legge inn om et kunnskapsmål er undervist. Denne informasjonen presenteres samlet for
                skoleledelsen, slik at det ikke vil være behov for en separat rapportering på underviste kunnskapsmål.
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" align="stretch" class="pt-3">
            <v-col cols="12" sm="9" md="7" lg="6" xl="5">
              <h1 style="color:red">Denne vil bli oppdatert</h1>
              <iframe
                src="https://player.vimeo.com/video/415468595"
                frameborder="0"
                width="100%"
                height="400"
                allow="fullscreen"
                allowfullscreen
              >
              </iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="getLang() === 'en'" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11" lg="11" xl="11">
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="11" lg="11" xl="11">
              <h1 class="heading1">From curriculum goal to learning goals..</h1>
              <p class="para">Based on the curriculum goal, you could provide more details for your learner...</p>
            </v-col>
          </v-row>
          <v-row justify="center" align="stretch" class="pt-3">
            <v-col cols="12" sm="9" md="7" lg="6" xl="5">
              <iframe
                src="https://player.vimeo.com/video/415468595"
                frameborder="0"
                width="100%"
                height="400"
                allow="fullscreen"
                allowfullscreen
              >
              </iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Works',
  data() {
    return {}
  },
  methods: {
    getLang() {
      return this.$store.getters.getLanguage
    }
  }
}
</script>

<style lang="scss" scoped>
.heading1 {
  font-size: calc(2rem + 1vmin);
  font-weight: 400;
  text-align: left;
}

.para {
  font-size: calc(0.8rem + 1vmin);
  font-weight: 400;
  text-align: left;
  margin-top: 1rem;
}
.para1 {
  font-size: calc(0.8rem + 1vmin);
  font-weight: 400;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
